<template>
  <div class="e-messages">
    <VuePerfectScrollbar class="e-messages__inner">
      <div
        v-if="dialogsLoading"
        class="e-messages__note e-messages__note--loading"
      >
        <Loading />
        <p>{{ $t('system.messages.loading') }}</p>
      </div>

      <template v-else>
        <MessageListItem
          v-for="message in items"
          :key="message.id"
          :data="message"
        />

        <div class="e-messages__note">
          {{ noMessages }}
        </div>
      </template>
    </VuePerfectScrollbar>

    <div v-if="showNewDialogButton" class="e-messages__add">
        <el-button type="tertiary" :class="{'is-disabled': counselingCenter && counselingCenter.registrationClosed}" @click="newDialogHandler">
          <span class="e-btn__text">
            {{ $t('system.messages.new') }}
          </span>
          <IconPlus class="e-mobile-hidden" />
        </el-button>
    </div>
    <change-counseling-center-prompt
      v-if="showChangeCounselingCenterPrompt"
      @counseling-center-changed="counselingCenterWasChangedHandler"
      @close-dialog="showChangeCounselingCenterPrompt = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

import Loading from 'atoms/Loading/Loading'
import MessageListItem from 'molecules/MessageListItem/MessageListItem'
import ChangeCounselingCenterPrompt from 'molecules/ChangeCounselingCenterPrompt/ChangeCounselingCenterPrompt'

// Icons
import IconPlus from 'assets/icons/plus.svg'

export default {
  name: 'MessageList',
  components: {
    IconPlus,
    Loading,
    MessageListItem,
    VuePerfectScrollbar,
    ChangeCounselingCenterPrompt
  },
  props: {
    showNewDialogButton: {
      type: Boolean,
      default: true
    },
    items: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  data: () => ({
    loading: false,
    showChangeCounselingCenterPrompt: false,
    showRegistrationClosedMessageText: false
  }),
  computed: {
    ...mapGetters(['dialogsLoading', 'userInfo', 'counselingCenterId', 'counselingCenters']),
    counselingCenter() {
      if (!this.counselingCenterId) {
        return undefined
      }
      return this.counselingCenters
        .filter(
          counselingCenter => counselingCenter.id === this.counselingCenterId
        )
        .pop()
    },
    noMessages() {
      return this.items.length > 0
        ? this.$t('system.messages.noMore')
        : this.$t('system.messages.noItems')
    }
  },
  methods: {
    newDialogHandler() {
      if (this.userInfo.counselingCenterDeleted) {
        this.showChangeCounselingCenterPrompt = true
        return
      }
      if (this.counselingCenter && this.counselingCenter.registrationClosed) {
        if (this.counselingCenter.registrationClosedMessageText) {
          this.$message({
            message: this.counselingCenter.registrationClosedMessageText,
            type: 'warning',
            duration: 20000,
            dangerouslyUseHTMLString: true,
            showClose: true
          });
        }
        return
      }
      this.newDialog()
    },
    counselingCenterWasChangedHandler() {
      this.showChangeCounselingCenterPrompt = false
      this.newDialog()
    },
    newDialog() {
      this.$router.push({
        name: 'newDialog'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_assets/messagelist';
</style>
