<template>
  <el-dialog
    :title="$t('system.counselingCenter.wasDeleted')"
    :visible.sync="open"
    :append-to-body="true"
    custom-class="el-dialog--small"
  >
    <div v-html="content" />
    <br />
    <el-select
      v-model="newCounselingCenter"
      :placeholder="$t('system.register.counselingCenter')"
    >
      <el-option
        v-for="center in counselingCenters"
        :key="center.id"
        :label="center.name"
        :value="center.id"
      />
    </el-select>

    <div slot="footer" class="dialog-footer">
      <el-button
        :loading="loading"
        type="primary"
        :disabled="!newCounselingCenter"
        @click="handleChangeCounselingCenter"
      >
        {{ $t('system.confirm') }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data: () => ({
    open: true,
    loading: false,
    newCounselingCenter: null
  }),

  computed: {
    ...mapGetters([
      'newDialogCounselingCenterDeletedMessage',
      'counselingCenters'
    ]),
    content() {
      return this.newDialogCounselingCenterDeletedMessage
    }
  },

  watch: {
    open: function(newVal) {
      if (newVal === false) {
        this.$emit('close-dialog')
      }
    }
  },
  created() {
    if (!(this.counselingCenters && this.counselingCenters.length)) {
      this.loadCounselingCenters()
    }
  },
  methods: {
    ...mapActions(['changeCounselingCenter', 'loadCounselingCenters']),
    async handleChangeCounselingCenter() {
      const counselingCenterId = this.newCounselingCenter
      if (!counselingCenterId) {
        return
      }
      this.loading = true
      try {
        await this.changeCounselingCenter(counselingCenterId)
        this.loading = false
      } catch (error) {
        console.error(error)
        this.loading = false
        return
      }
      this.$emit('counseling-center-changed')
    }
  }
}
</script>
